/* eslint-disable */
import React from "react";
import Button from "react-bootstrap/Button";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../forgot-my-password/style.scss'
import axios from 'axios'
import {vendorUUID} from "../../utils/constants";
import {getPasswordResetBaseURL} from '../../utils/getPasswordResetBaseURL';

export default class extends React.Component {

  state = {
    passwordResetMessage: '',
    validationMessage: '',
    email: '',
    confirmPassword: ''
};

constructor(props) {
    super(props);

}

forgotPasswordClicked = () => {
    const {email} = this.state;
    if (!email) {
        this.setState({
            validationMessage: 'You must enter your email address'
        })
    }else if(email.indexOf('@') === -1 || email.indexOf('.') === -1){
        this.setState({
            validationMessage: 'Please enter a valid email address'
        })
    }else {
        axios({
            method: 'post',
            url: `${getPasswordResetBaseURL}/reset-account-password`,
            data: {
                username: `${email}#${vendorUUID}`,
                resetRedirectUri: `https://nobsapp.co.uk/reset-password/?code=`
            }
        }).then(response => {
            console.log('response', response)
            if(response.data.errorMessage){
                this.setState({
                    validationMessage: response.data.errorMessage
                })
            }else{
                this.setState({
                    passwordResetMessage: `An email with instructions on how to reset your password has been sent to ${email}.`
                })
            }
        }).catch(error => {
            console.log("error")
            console.log(error)
            console.log(error.message)
            this.setState({
                validationMessage: error.response.data.errorMessage
            })
        })
    }
}

render() {
  const {
      passwordResetMessage,
      validationMessage,
      email,
  } = this.state
  return (
    <Layout>
      <SEO title="Reset Password" />
      <Container fluid className="join-page reset-password">
        <Row>
          <Col className="left-col">
            <Col className="text-center">
              <div className="form-box">
                  {passwordResetMessage && (
                      <div className="password-success-reset-message">
                          {passwordResetMessage}
                      </div>
                  )}
                  {!passwordResetMessage && (
                <div className="Login">
                  <h2>Forgot your password?</h2>

                  {/*<p>Please contact <a href="https://support.nobsapp.co.uk/hc/en-us">support center</a> to reset your password.</p>*/}
                  <p>
                    Please enter your email below to recover your account using
                    an email code.
                  </p>
                  <form className="form-signin mt-4">
                    <div className="form-label-group">
                      <input
                        autoFocus
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        value={email}
                                      onChange={({target}) => {
                                          this.setState({
                                              validationMessage: '',
                                              email: target.value.toLowerCase().replace(' ', ''),
                                          })
                                      }}
                      />
                    </div>
                      {
                          !!validationMessage && (
                              <p>{validationMessage}</p>
                          )
                      }
                    <Button block variant="secondary" onClick={this.forgotPasswordClicked}>Reset</Button>
                  </form>
                </div>
                  )}
              </div>
            </Col>
          </Col>
          {/* <Col className="right-col"></Col> */}
        </Row>
      </Container>
    </Layout>
  )
}
}
